import React from 'react';

import { useRouter } from 'next/router';

import Trans from '@components/Trans';
import Container from '@components/global/Container';
import TwoColumns from '@components/global/design2023/TwoColumns';
import DeskoptBr from '@components/ui/DeskoptBr';
import Underline from '@components/ui/Underline';
import { CTAStartFreeCreateAccount } from '@components/ui/design2023/CTAStartFreeCreateAccount';

import useTranslation from '@hooks/useTranslation';

import styles from './CoursesSection.module.css';

interface CoursesSectionProps {
    comingSoon?: boolean;
    preheader?: JSX.Element;
    title?: JSX.Element;
    featuresList?: JSX.Element;
    description?: JSX.Element;
    button?: JSX.Element;
    maxWidth?: string;
    blackFriday24PromoActive?: boolean;
}

const CoursesSection = ({
    maxWidth,
    preheader,
    title,
    featuresList,
    description,
    button,
    comingSoon = true,
    blackFriday24PromoActive = false,
}: CoursesSectionProps) => {
    const ns = 'base/_blocks/courses_section_2024';
    const { t } = useTranslation(ns);
    const { locale } = useRouter();

    const preparePreheader = preheader ? (
        preheader
    ) : (
        <Trans
            i18nKey={'Preheader'}
            components={{
                br: <DeskoptBr />,
            }}
            ns={ns}
        />
    );

    const prepareTitle = title ? (
        title
    ) : (
        <Trans
            i18nKey={'Title'}
            components={{
                u: <Underline />,
                br: <DeskoptBr />,
            }}
            ns={ns}
        />
    );

    const prepareDescription = description ? (
        description
    ) : (
        <Trans
            i18nKey={'Description'}
            components={{
                p: <p />,
                strong: <strong />,
                br: <DeskoptBr />,
            }}
            ns={ns}
        />
    );

    const prepareFeaturesList = featuresList ? (
        featuresList
    ) : (
        <Trans
            i18nKey={'FeaturesList'}
            components={{
                ul: <ul />,
                li: <li />,
            }}
            ns={ns}
        />
    );

    const video_source = {
        pl: 'Lm-bZALp-s0',
        de: 'j0fg2pieJZI',
        it: 'XChK7Ni3J6o',
        default: 'iojlGiU4ACE',
    };
    const video =
        video_source[locale as keyof typeof video_source] ??
        video_source.default;

    const VideoSource = () => {
        return (
            <iframe
                loading="lazy"
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${video}?rel=0`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
            />
        );
    };

    return (
        <section className={styles.gradient}>
            <Container maxWidth={maxWidth}>
                <div className={styles.courses}>
                    {blackFriday24PromoActive && (
                        <div className={styles.blackFriday24Badge}>CREATOR</div>
                    )}
                    {!blackFriday24PromoActive && comingSoon && (
                        <span className={styles.label}>{t('Label')}</span>
                    )}
                    <div className={styles.header}>
                        <p className={styles.preheader}>{preparePreheader}</p>
                        <h2 className={styles.title}>{prepareTitle}</h2>
                    </div>
                    <TwoColumns
                        className={styles.hero}
                        customStyles={{
                            'video-wrapper': styles['video-wrapper'],
                        }}
                        position="odd"
                        description={
                            <>
                                {prepareDescription ? (
                                    <div className={styles.text}>
                                        {prepareDescription}
                                    </div>
                                ) : null}
                                <div className={styles.list}>
                                    {prepareFeaturesList}
                                </div>
                            </>
                        }
                        video={VideoSource()}
                    />
                    {button ? (
                        button
                    ) : (
                        <CTAStartFreeCreateAccount
                            params={{ pageinfo: 'homepage_creators' }}
                            className={styles.button}
                            label={t('Btn')}
                            size="large"
                        />
                    )}
                </div>
            </Container>
        </section>
    );
};
export default CoursesSection;
