import React from 'react';

import Trans from 'next-translate/Trans';

import { useIntersectionObserverRef } from '@components/global/PromoBlackFriday2024/hooks';
import { Button } from '@components/ui/design2023/Button';

import useTranslation from '@hooks/useTranslation';

import { BFCM2024GtmEvent } from '../../../global/PromoBlackFriday2024/utils';
import styles from './BFCM2024ChallengeBanner.module.css';
import { Hero1xAVIF, Hero1xWEBP, Hero2xAVIF, Hero2xWEBP } from './assets';

const BFCM2024_CHALLENGE_BANNER_NAMESPACE = 'promo/black_friday_2024/HomepageChallengeBanner/index';

export function BFCM2024ChallengeBanner() {
    const { t } = useTranslation(BFCM2024_CHALLENGE_BANNER_NAMESPACE);

    const handleViewedEvent = React.useCallback(() => {
        new BFCM2024GtmEvent({
            state: 'viewed',
            placement: 'homepage-challenge-section',
        });
    }, []);

    const bannerRef = useIntersectionObserverRef<HTMLDivElement>(handleViewedEvent);

    const handleClick = React.useCallback(() => {
        new BFCM2024GtmEvent({ state: 'buttonClicked', placement: 'homepage-challenge-section' });
    }, []);

    return (
        <div
            ref={bannerRef}
            className={styles.banner}
        >
            <div className={styles.container}>
                <picture className={styles.image}>
                    <source
                        type="image/avif"
                        srcSet={`${Hero1xAVIF.src} 1x, ${Hero2xAVIF.src} 2x`}
                    />
                    <source srcSet={`${Hero1xWEBP.src} 1x, ${Hero2xWEBP.src} 2x`} />
                    <img
                        width={Hero1xWEBP.width}
                        height={Hero1xWEBP.height}
                        decoding="async"
                        loading="lazy"
                        alt=""
                        src={Hero1xWEBP.src}
                    />
                </picture>
                <div className={styles.text}>
                    <Trans
                        i18nKey="Text"
                        components={{
                            span: <span />,
                        }}
                        ns={BFCM2024_CHALLENGE_BANNER_NAMESPACE}
                    />
                </div>
                <div className={styles.buttonWrap}>
                    <Button
                        className={styles.button}
                        type="button"
                        label={t('Button')}
                        size="medium"
                        href="https://creator-challenge.grweb.site/"
                        onClick={handleClick}
                    />
                </div>
            </div>
        </div>
    );
}
