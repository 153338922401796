import React, { useCallback, useEffect, useState } from 'react';

import Trans from '@components/Trans';
import CustomLink from '@components/global/CustomLink';
import DeskoptBr from '@components/ui/DeskoptBr';
import { Button } from '@components/ui/design2023/Button';
import { CTAStartFreeCreateAccount } from '@components/ui/design2023/CTAStartFreeCreateAccount';

import useTranslation from '@hooks/useTranslation';
import clsx from 'clsx';

import Checkbox from './Checkbox';
import styles from './ExitPopup.module.css';
import Heros from './Heros';
import { ReactComponent as SVGCloseIcon } from './assets/close.svg';

export type Plan = 'P0' | 'P1' | 'P2' | 'P3' | 'P4' | 'P5' | 'P6';

const ExitPopup = ({ promoCode }: { promoCode?: string }) => {
    const ns = 'promo/black_friday_2024/exit_popup/index';
    const { t } = useTranslation(ns);

    type Question = 1 | 2;

    const [activeQuestion, setactiveQuestion] = useState<Question>(1);
    const [choosenPlan, setChoosenPlan] = useState<Plan>('P0');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTriggered, setDialogTriggered] = useState(false);
    const [selectedPlans, setSelectedPlans] = useState<Plan[]>(['P0']);

    const planLinks: {
        [key in Plan]: {
            url: string;
            pageinfo?: string;
            label?: string;
            event?: string;
        };
    } = {
        P0: { url: 'pricing' },
        P1: {
            url: 'start-free',
            pageinfo: 'pricing_email_marketing',
            event: 'bfcm24_popup_clicked_P1',
        },
        P2: {
            url: 'start-free',
            pageinfo: 'pricing_marketing_automation',
            event: 'bfcm24_popup_clicked_P2',
        },
        P3: {
            url: 'start-free',
            pageinfo: 'pricing_ecommerce_marketing',
            event: 'bfcm24_popup_clicked_P4',
        },
        P4: {
            url: 'max/book-a-demo',
            label: t('exitBtnMaxPlan'),
            event: 'bfcm24_popup_clicked_P5',
        },
        P5: {
            url: 'start-free',
            pageinfo: 'pricing_page_creators',
            event: 'bfcm24_popup_clicked_P3',
        }, // creators overwrites everything except P6 but GA event has to be 'bfcm24_popup_clicked_P3'
        P6: {
            url: 'max/book-a-demo',
            label: t('exitBtnMaxPlan'),
            event: 'bfcm24_popup_clicked_P5',
        },
    };
    const question1Answers: { answer: string; value: Plan }[] = [
        { answer: t('exitQuestion1Answer1'), value: 'P1' },
        { answer: t('exitQuestion1Answer2'), value: 'P2' },
        { answer: t('exitQuestion1Answer3'), value: 'P5' },
        { answer: t('exitQuestion1Answer4'), value: 'P3' },
    ];

    const question2Answers: { answer: string; value: Plan }[] = [
        { answer: t('exitQuestion2Answer1'), value: 'P1' },
        { answer: t('exitQuestion2Answer2'), value: 'P1' },
        { answer: t('exitQuestion2Answer3'), value: 'P2' },
        { answer: t('exitQuestion2Answer4'), value: 'P5' },
        { answer: t('exitQuestion2Answer5'), value: 'P4' },
        { answer: t('exitQuestion2Answer6'), value: 'P6' },
    ];

    interface MyMouseEvent extends MouseEvent {
        toElement: EventTarget | null;
    }

    useEffect(() => {
        if (dialogOpen) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'bfcm24_popup_viewed',
                placement: 'Homepage',
            });
        }
    }, [dialogOpen]);

    useEffect(() => {
        if (dialogOpen) {
            if (activeQuestion === 1) {
                window.dataLayer.push({
                    event: 'bfcm24_popup_step_1_viewed',
                    placement: 'Homepage',
                });
            } else if (activeQuestion === 2) {
                window.dataLayer.push({
                    event: 'bfcm24_popup_step_2_viewed',
                    placement: 'Homepage',
                });
            }
        }
    }, [activeQuestion, dialogOpen]);

    const handleMouseMove = useCallback(
        (e: MouseEvent) => {
            const myEvent = e as MyMouseEvent;
            if (!dialogOpen && !dialogTriggered) {
                if (!myEvent.toElement && !myEvent.relatedTarget && myEvent.clientY < 10) {
                    const timesShown = localStorage.getItem('exitPopupShown');
                    if (timesShown !== '2') {
                        setDialogOpen(true);
                        setDialogTriggered(true);
                        if (timesShown === null) {
                            localStorage.setItem('exitPopupShown', '1');
                        } else if (timesShown === '1') {
                            localStorage.setItem('exitPopupShown', '2');
                        }
                        document.body.style.overflow = 'hidden';
                        document.removeEventListener('mouseout', handleMouseMove);
                    }
                }
            }
        },
        [dialogOpen, dialogTriggered],
    );

    const handleTouch = useCallback(
        (e: TouchEvent) => {
            if (!dialogOpen && !dialogTriggered) {
                const timesShown = localStorage.getItem('exitPopupShown');
                if (timesShown !== '2') {
                    setDialogOpen(true);
                    setDialogTriggered(true);
                    if (timesShown === null) {
                        localStorage.setItem('exitPopupShown', '1');
                    } else if (timesShown === '1') {
                        localStorage.setItem('exitPopupShown', '2');
                    }
                    document.body.style.overflow = 'hidden';
                    document.body.removeEventListener('touchstart', handleTouch);
                }
            }
        },
        [dialogOpen, dialogTriggered],
    );

    const handleDialogClose = () => {
        document.body.style.overflow = '';
        setDialogOpen(false);
    };

    const handleDialogCloseBlend = useCallback((e: React.MouseEvent<HTMLDialogElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            handleDialogClose();
        }
    }, []);

    useEffect(() => {
        const timeoutMobile = setTimeout(() => {
            document.body.addEventListener('touchstart', handleTouch);
        }, 7000);
        const timeout = setTimeout(() => {
            document.addEventListener('mouseout', handleMouseMove);
        }, 1000);

        return () => {
            clearTimeout(timeout);
            clearTimeout(timeoutMobile);
            document.body.removeEventListener('touchstart', handleTouch);
            document.removeEventListener('mouseout', handleMouseMove);
        };
    }, [handleMouseMove, handleTouch]);

    const goToNextQuestion = (questionNumber: Question) => {
        setactiveQuestion(questionNumber);
    };

    const clickAnswer = (answer: Plan, checked: boolean) => {
        let selectedPlansCopy = selectedPlans;

        if (checked) {
            selectedPlansCopy.push(answer);
        } else {
            const lastOccurence = selectedPlansCopy.lastIndexOf(answer);
            selectedPlansCopy = [
                ...selectedPlansCopy
                    .filter((v, i) => i !== lastOccurence)
                    .map((item) => {
                        return item;
                    }),
            ];
        }
        setSelectedPlans(selectedPlansCopy.sort());
        setChoosenPlan(selectedPlansCopy.sort()[selectedPlansCopy.length - 1]);
    };

    const handleChooseCharacter = useCallback((event?: string) => {
        window.dataLayer = window.dataLayer || [];
        if (event) {
            window.dataLayer.push({ event, placement: 'Homepage' });
        }
        window.dataLayer.push({ event: 'bfcm24_popup_step_2_button_clicked', placement: 'Homepage' });
        localStorage.setItem('exitPopupShown', '2');
        document.body.style.overflow = '';
    }, []);

    return (
        <dialog
            className={clsx(styles.dialogContainer, styles.dialogAnimation)}
            open={dialogOpen}
            onClick={(e) => handleDialogCloseBlend(e)}
        >
            <div className={styles.dialog}>
                <button
                    className={styles.close}
                    onClick={() => handleDialogClose()}
                >
                    <SVGCloseIcon
                        width={24}
                        height={24}
                    />
                </button>
                <div className={styles.inner}>
                    <Heros />
                    <h2>
                        <Trans
                            i18nKey={'exitTitle'}
                            components={{
                                span: <span />,
                                br: <DeskoptBr />,
                            }}
                            ns={ns}
                        />
                    </h2>
                    <div className={clsx(styles.question, styles.questionOne, activeQuestion === 1 && styles.active)}>
                        <p>{t('exitTextQ1')}</p>
                        <h3>{t('exitQuestion1')}</h3>
                        <div className={styles.growWrap}>
                            <ul className={styles.answers}>
                                {question1Answers.map((item, i) => {
                                    return (
                                        <li key={i}>
                                            <Checkbox
                                                label={item.answer}
                                                value={item.value}
                                                name={`question1-answer${i}`}
                                                onClick={clickAnswer}
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <Button
                            label={t('exitBtnGoToNext')}
                            size="large"
                            color="yellow"
                            onClick={() => goToNextQuestion(2)}
                        />
                    </div>

                    <div className={clsx(styles.question, styles.questionTwo, activeQuestion === 2 && styles.active)}>
                        <p>{t('exitTextQ2')}</p>
                        <h3>{t('exitQuestion2')}</h3>
                        <div className={styles.growWrap}>
                            <ul className={styles.answers}>
                                {question2Answers.map((item, i) => {
                                    return (
                                        <li key={i}>
                                            <Checkbox
                                                label={item.answer}
                                                value={item.value}
                                                name={`question2-answer${i}`}
                                                onClick={clickAnswer}
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        22
                        {planLinks[choosenPlan].url === 'start-free' ? (
                            <CTAStartFreeCreateAccount
                                params={{
                                    code: promoCode,
                                    pageinfo: planLinks[choosenPlan].pageinfo,
                                }}
                                label={t('exitBtnStartFree')}
                                size="large"
                                color="yellow"
                                onClick={() => handleChooseCharacter(planLinks[choosenPlan].event)}
                            />
                        ) : (
                            <Button
                                label={planLinks[choosenPlan].label ?? t('exitBtnNoPlan')}
                                size="large"
                                color="yellow"
                                customLink={
                                    <CustomLink
                                        href={{
                                            pathname: planLinks[choosenPlan].url,
                                        }}
                                    />
                                }
                                onClick={() => handleChooseCharacter(planLinks[choosenPlan].event)}
                            />
                        )}
                    </div>
                </div>
            </div>
        </dialog>
    );
};

export default ExitPopup;
