import React, { cloneElement } from 'react';

import { useRouter } from 'next/router';

import Trans from 'next-translate/Trans';

import { Button } from '@components/ui/design2023/Button';
import { CTAStartFreeCreateAccount } from '@components/ui/design2023/CTAStartFreeCreateAccount';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteStartFree } from '@static_components/laravelLinks';

import styles from './EmailMarketingAndAutomationBanner.module.css';
import SVGTrophy from './trophy.svg';

const Content = () => {
    const { t } = useTranslation('pages/homepage/2024/v1/index');
    const { locale } = useRouter();
    return (
        <aside className={styles.wrapp}>
            <div className={styles.banner}>
                <p>
                    <Trans
                        i18nKey="emailMarketingAndAutomationBanner"
                        components={{
                            u: <span />,
                        }}
                        ns="pages/homepage/2024/v1/index"
                    />
                </p>
                <CTAStartFreeCreateAccount
                    label={t('emailMarketingAndAutomationBannerButton')}
                    color="black"
                />
            </div>
            <div className={styles.footnote}>
                <div className={styles.wrapper}>
                    <img
                        src={SVGTrophy.src}
                        alt=""
                        width={SVGTrophy.width}
                        height={SVGTrophy.height}
                        loading="lazy"
                    />
                    <p>
                        <Trans
                            i18nKey="footnoteMarketingAndAutomationBanner_v_b"
                            components={{
                                span: <span />,
                            }}
                            ns="pages/homepage/2024/v1/index"
                        />
                    </p>
                </div>
            </div>
        </aside>
    );
};

interface PricingBannerProps {
    container: React.ReactElement;
}

export default function PricingBanner({
    container,
}: PricingBannerProps): JSX.Element {
    return (
        <section className={styles.hero}>
            {cloneElement(container, {
                children: <Content />,
            })}
        </section>
    );
}
